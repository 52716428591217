// eslint-disable-next-line no-unused-vars
import React from "react";
import { useRoutes } from "react-router";
import appRoutes from "./router";

const App = () => {
  const routes = useRoutes(appRoutes());

  return routes;
};

export default App;
