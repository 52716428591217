import React, { useState } from "react";
import { loginApi, verifyApi } from "../service/auth";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState(null);
  const [verified, setVerified] = useState(false);
  const [ready, setReady] = useState(false);
  const [otp, setOtp] = useState("");

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      const loginResult = await loginApi(username, password);
      setResult(loginResult?.data);
      if (loginResult?.data?.otp) {
        setReady(true);
      }
    } catch (error) {
      setResult({ status: "error", msg: "unexpected error" });
      if (error.response?.data?.msg) {
        alert(error.response?.data?.msg);
      } else {
        alert("Internal Error");
      }
    }
  };

  const handleVerify = async (e) => {
    try {
      e.preventDefault();
      const verifyResult = await verifyApi(result?.id, otp);
      if (verifyResult?.data?.status === "success") {
        console.log("ok");
        setVerified(true);
      }
    } catch (error) {
      if (error.response?.data?.msg) {
        alert(error.response?.data?.msg);
      } else {
        alert("Internal Error");
      }
    }
  };

  return (
    <main className="login-page" onSubmit={handleLogin}>
      <div className="login-wrapper">
        <h4>Login</h4>
        <br />
        {result && result.status === "success" && !result?.otp && !ready ? (
          <div>
            <img
              src={`https://totp.itdogs.xyz/api/auth/qrcode/${result?.id}`}
              alt="qrcode"
            />
            <br />
            <br />
            <p>Please scan the code</p>
            <br />
            <button
              className="btn btn-primary container-fluid"
              type="button"
              onClick={() => setReady(true)}
            >
              Next
            </button>
          </div>
        ) : ready && !verified ? (
          <form className="login-form-wrapper" onSubmit={handleVerify}>
            <input
              className="form-control"
              value={otp}
              type="text"
              placeholder="Otp Code"
              required
              onChange={(e) => setOtp(e.currentTarget.value)}
            />
            <br />
            <button className="btn btn-primary container-fluid" type="submit">
              Submit
            </button>
          </form>
        ) : verified ? (
          <h3>Passed</h3>
        ) : (
          <form className="login-form-wrapper">
            <input
              className="form-control"
              value={username}
              type="text"
              placeholder="Username"
              required
              onChange={(e) => setUsername(e.currentTarget.value)}
            />
            <br />
            <input
              className="form-control"
              value={password}
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <br />
            <button className="btn btn-primary container-fluid" type="submit">
              Login
            </button>
          </form>
        )}
      </div>
    </main>
  );
};

export default LoginPage;
